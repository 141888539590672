import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import MenuData from '../../currentMenu.json'
import CurrentMenu from '../../currentMenu.json'
import { ItemIds } from '../content/menu-items'
import Brisket from '../assets/brisket.webp'
import Tritip from '../assets/tritip.webp'
import PulledPork from '../assets/pulledPork.webp'
import PorkBelly from '../assets/porkBelly.webp'
import ChickenBreast from '../assets/chickenBreast.webp'
import FiletMignon from '../assets/filetMignon.webp'
import Tzatziki from '../assets/tzatziki.webp'
import CabbageSalad from '../assets/cabbageSalad.webp'
import TritipChili from '../assets/chili.webp'
import Hummus from '../assets/hummus.webp'

const itemImages = [FiletMignon, ChickenBreast, PorkBelly, Brisket, Tritip, PulledPork, Tzatziki, CabbageSalad, TritipChili]


const MenuOfTheDay: React.FC = () => {
 
  return (
    <Box sx={{
      textAlign: 'center',
      alignItems:'center',
      justifyContent:'center',
      maxWidth:'800px',
      m:'auto',
      pt:'200px',
      color: 'white'
    }}>
      <h1>Today's Menu</h1>
      <Typography>
        Ask about our sample for the day!
      </Typography>
      <Box>
        {CurrentMenu.menu.map((item) => (
          <Box key={item.id}>
          { item.isAvailable &&
          <Grid container px={2} sx={{ mt: '15px', mb: '15px'}}>
            
            <Grid
                item 
                xs={12} md={6} m='auto' p={2}
                bgcolor='#1a1a1a' borderRadius={{md:'12px 0px 0px 12px', xs:'12px 12px 0px 0px'}}
            >
                <img src={itemImages[item.id]} style={{ width: '100%', filter: 'drop-shadow(0px 8px 8px black)', borderRadius:'12px' }}/>
            </Grid>

            <Grid
                item 
                xs={12} md={6} p={2}
                bgcolor='#1a1a1a'  borderRadius={{md:'0px 12px 12px 0px', xs:'0px 0px 0px 0px'}}
            >
                <Typography  mb={2} sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }}>
                    {item.name}
                </Typography>
                <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                    {item.description}
                </Typography>
            </Grid>
            
          </Grid>
          }
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default MenuOfTheDay;
