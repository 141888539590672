import React from 'react';
import { Box, Grid, Table, TableBody, TableHead, TableRow, Typography } from '@mui/material';
import { VendingInfo } from './vendingTypes';
import { NavLink } from 'react-router-dom';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { Place } from '@mui/icons-material';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);



const VendingCards = ({data}: {data:VendingInfo[]}) => {
 
  return (
    <Box sx={{
      textAlign: 'center',
      alignItems:'center',
      justifyContent:'center',
      maxWidth:'400px',
      m:'auto',
      color: 'white'
    }}>

        <Grid 
            container 
            columns={2} 
            m='auto' 
            p='auto' 
            alignItems='center' 
            justifyContent='center'
        >
            {data.map(serviceData => 
                <Grid 
                    container 
                    alignItems='center' 
                    m='auto' 
                    p={2} 
                    key={serviceData.date} 
                    sx={{
                            ':nth-of-type(odd)':{ 
                                bgcolor:'rgba(255,255,255,.1)'
                            }
                    }}
                >
                    <Grid item xs={3} textAlign='left'  pt={.5} pb={.5}>
                        Date:
                    </Grid>
                    <Grid item xs={9} textAlign='right' pt={.5} pb={.5}>
                        { dayjs(serviceData.date).format("dddd MMMM Do, YYYY")}
                    </Grid>
                    <Grid item xs={3} textAlign='left'  pt={.5} pb={.5}>
                        Time:
                    </Grid>
                    <Grid item xs={9} textAlign='right' pt={.5} pb={.5}>
                        {serviceData.serviceTime}
                    </Grid>
                    <Grid item xs={3} textAlign='left'  pt={.5} pb={.5}>
                        Location:
                    </Grid>
                    <Grid item xs={9} textAlign='right' pt={.5} pb={.5}>
                        <NavLink to={serviceData.mapLink} className='nav-styles'>
                            <Grid  container justifyContent='right'>
                                <Grid item >
                                    <Place/> 
                                </Grid>
                                <Grid item >
                                    {serviceData.location}
                                </Grid>
                            </Grid>
                        </NavLink>
                    </Grid>
                </Grid>
            )}
        </Grid>
    </Box>
  );
};

export default VendingCards;
