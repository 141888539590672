import React from "react";
import { Box, Link, Typography, IconButton, Grid, List, ListItem, Stack, ListItemIcon, Icon } from "@mui/material"
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Grilling from '../assets/meat-sizzle-desktop-lq.webm'
import GrillingPhone from '../assets/meat-sizzle-mobile-lq.webm'
import GrillIcon from '../assets/grillIcon.png'
import Rice from '../assets/rice.webp'
import SutDolma from '../assets/veganDolma.webp'
import MedetteraneanDish from '../assets/medetteraneanVeggiesAndChickpeas.webp'
import './landingPage.css'
import InstagramIcon from '../assets/instagram-logo.webp'
import EmailRestAPI from "../components/EmailRestAPI";
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import CCText from '../assets/cc-text.webp'
import CCTextMobile from '../assets/cc-text-mobile.webp'
import { keyframes } from "@mui/material";
import Pamphlet from '../assets/pamphlet.webp'
import Brisket from '../assets/brisket.webp'
import Tritip from '../assets/tritip.webp'
import PulledPork from '../assets/pulledPork.webp'
import PorkBelly from '../assets/porkBelly.webp'
import ChickenBreast from '../assets/chickenBreast.webp'
import FiletMignon from '../assets/filetMignon.webp'
import Tzatziki from '../assets/tzatziki.webp'
import CabbageSalad from '../assets/cabbageSalad.webp'
import Hummus from '../assets/hummus.webp'
import { veganEntreeData } from "../content/menu-items";
import VeganIcon from "../assets/veganIcon.webp"
import { YelpReviewWidget } from "../components/yelp-reviews/YelpReviewWidget";
import VendingSchedule from "./VendingSchedule";
import vendingSchedule from '../../vendingSchedule.json'
import AnchorLink from "react-anchor-link-smooth-scroll";
import { NavLink } from "react-router-dom";

// const grandOpening = keyframes `
//     0%   {text-shadow: #A67C00 -1px -1px 2px;}
    

    
//     50%  {text-shadow: #FFDC73 -1px -1px 5px;}
    
  
    
//     100% {text-shadow: #A67C00 -1px -1px 2px;}
// `


const grandOpening = `
    filter: drop-shadow( 0px 0px 4px rgba(255, 255, 255, .9));
`

const location = `
https://www.google.com/maps/place/34%C2%B009'39.2%22N+118%C2%B017'19.7%22W/@34.160907,-118.2888162,59m/data=!3m1!1e3!4m4!3m3!8m2!3d34.160892!4d-118.288793?entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D
`

/*
//// Use to  configure get request for yelp reviews

const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: 'bearer 4aRZt7u5A8ofCKSpfMxq5D1f3Tn7uPtgsQKKlMkAl_Hc_FHsDUaIL1wsXyc2mOZqGs5c-1n0ZrMlXno6E-S8xZAXtnZpngQTUZraNWUO45PcURdEmmV53Ub5Duh2Z3Yx'
    }
  };
  
  fetch('https://api.yelp.com/v3/businesses/YuzfrLFr4Tnl6nQerMgIEw/reviews?locale=en_US&offset=0&limit=20&sort_by=yelp_sort', options)
    .then(res => res.json())
    .then(res => console.log(res))
    .catch(err => console.error(err));

*/


/*
//// Use to get info about the business

const options = {
  method: 'GET',
  headers: {
    accept: 'application/json',
    Authorization: 'bearer 4aRZt7u5A8ofCKSpfMxq5D1f3Tn7uPtgsQKKlMkAl_Hc_FHsDUaIL1wsXyc2mOZqGs5c-1n0ZrMlXno6E-S8xZAXtnZpngQTUZraNWUO45PcURdEmmV53Ub5Duh2Z3Yx'
  }
};

fetch('https://api.yelp.com/v3/businesses/YuzfrLFr4Tnl6nQerMgIEw?locale=en_US', options)
  .then(res => res.json())
  .then(res => console.log(res))
  .catch(err => console.error(err));

*/


function LandingPage() {

    const [trigger, setTrigger] = React.useState(false)    

    useScrollPosition(({ prevPos, currPos }) => {
        const isShow = currPos.y === 0
        if (!isShow) {
          setTrigger(true)
        } else {
          setTrigger(false)
        }
      }, [])

    return (        
        <Box>
            {/* Section 1: Landing */}            
            <Box sx={{ display: { xs: 'none', md: 'block'}}}>
                <Box 

                    sx={{
                        width:'100%', 
                        position: 'absolute',
                        marginTop: '250px', 
                        transition:'opacity .5s ease-in-out', 
                        opacity: trigger? 0:1, 
                        zIndex: 100,
                        justifyContent:'center', alignItems:'center', textAlign:'center'
                    }}
                >

                    <img src={CCText} 
                        style={{  
                            maxWidth:'50vw', 
                            zIndex: 1,
                            filter: 'drop-shadow(0px 0px 3px rgba(0,0,0,1))', 
                        }} 
                    />
        
                    <Typography 
                        textAlign='center' 
                        variant="h2" 
                        sx={{ 
                            color: 'white', 
                            fontWeight:'bold', 
                            fontSize: 'clamp(30px, 2vw, 3vw)', 
                            zIndex: 1, 
                            filter: 'drop-shadow(0px 0px 2px rgba(0,0,0,1))', 
                            mt: '50px'
                        }} 
                    >
                        Savor the flavor
                    </Typography>
                </Box>
                <video src={Grilling} muted controls={false} autoPlay loop style={{ backgroundSize: 'cover', width: '100%', opacity: '80%'  }} /> 
            </Box>               
            <Box sx={{ display: { xs: 'block', md: 'none'}}}>               
                    <Box 
                        sx={{ 
                            position: 'absolute', 
                            marginTop: '250px', 
                            transition:'opacity .5s ease-in-out', 
                            opacity: trigger? 0:1, 
                            zIndex: 100,
                            justifyContent:'center', alignItems:'center', textAlign:'center'
                        }}
                    >

                        <img src={CCTextMobile} style={{  maxWidth:'100%',  zIndex: 1,  filter: 'drop-shadow(0px 0px 3px rgba(0,0,0,1))' }} />

                        <Typography 
                            textAlign='center' 
                            variant="h2" 
                            sx={{ 
                                marginTop:'50px',
                                color: 'white', 
                                fontWeight:'bold', 
                                fontSize: 'clamp(16px, 6vw, 24px)', 
                                zIndex: 1, 
                                filter: 'drop-shadow(0px 0px 2px rgba(0,0,0,1))', 
                            }} 
                        >
                            Savor the flavor
                        </Typography>
                    </Box>
                <video src={GrillingPhone} muted controls={false} autoPlay loop style={{ backgroundSize: 'cover', width: '100%', opacity: '80%'  }} /> 
            </Box>    
            {/* Section 2: Location */}
            <Box 
                sx={{ 
                    textAlign: 'center',
                    alignItems:'center',
                    justifyContent:'center',
                    maxWidth:'1200px',
                    m:'auto',
                    mt:'100px',
                }}
            >                   
                    <YelpReviewWidget/>
            </Box>
            <Box
                sx={{ 
                    textAlign: 'center',
                    alignItems:'center',
                    justifyContent:'center',
                    maxWidth:'1200px',
                    m:'auto',
                    mt: '-100px'
                }}
            >
                <VendingSchedule data={vendingSchedule.schedule.slice(0,5)}/>
            </Box>

            <Box
                sx={{ 
                    textAlign: 'center',
                    alignItems:'center',
                    justifyContent:'center',
                    maxWidth:'1200px',
                    m:'auto',
                    mt: '50px',
                    mb:'100px'
                }}
            >
                <NavLink to={'/VendingSchedule'} className='nav-styles' style={{fontSize:'22px'}}>
                    See our full schedule!
                </NavLink>
                
            </Box>

            <Box>                   
                {/* <Box sx={{ mx: 'auto'  }}>
                    <Typography sx={{ 
                        color: '#FFFFFF', 
                        fontSize: '45px', 
                        mt: 5, 
                    textAlign: 'center' }}>
                        Location:
                    </Typography>
                </Box>                
                <Box id="location" sx={{ 
                    maxWidth: { xs: '100%', sm: "600px"}, 
                height: { xs: '100%'}, 
                bgcolor: '#212021', 
                mx: 'auto', 
                borderRadius: '15px' }}>
                    <Typography sx={{ 
                        fontSize: { xs: '20px', sm: '24px', md: '30px'}, 
                    p: '2.5%', 
                    textAlign: 'center', 
                    color: '#FFFFFF'}}>
                        <Link 
                            href={location}
                            rel='norefferer noopener' target="_blank"
                            style={{ 
                                color : "white",
                                textDecoration: 'none'
                            }}
                        >
                            1300 Flower Street, Glendale, CA 91201
                        </Link>
                    </Typography>
                </Box>
                 */}
                <Typography
                    sx={{
                        mt: '35px',
                        fontSize: '20px',
                        color: 'white',
                        textAlign: 'center',
                        mx: 'auto'
                    }}
                >
                    Open for catering only: 
                </Typography>
                <Typography
                sx={{
                    mt: '5px',
                    fontSize: '20px',
                    color: 'white',
                    textAlign: 'center',
                    mx: 'auto'
                    }}>
                    Tuesday - Sundays
                    <span style={{
                        fontSize:'14px',
                        display: 'block'
                    }}>
                    For parties larger than 5.
                    </span>
                </Typography>    
                <Typography
                sx={{
                    mt: '5px',
                    fontSize: '20px',
                    color: 'white',
                    textAlign: 'center',
                    mx: 'auto'
                    }}
                >
                        
                </Typography>       
                <Typography
                    sx={{
                        mt: '30px',
                        fontSize: '20px',
                        color: 'white',
                        textAlign: 'center',
                        mx: 'auto'
                    }}
                >
                    Please give us at least 5 days notice, as all of our food is prepared Fresh! 
                </Typography>     
            </Box>
            <Box 
                sx={{ 
                    maxWidth: { xs: '100%', sm: "600px"}, 
                    height: { xs: '100%'}, 
                    bgcolor: '#212021', 
                    mx: 'auto', 
                    borderRadius: '15px',
                    mt: '35px',
                    pt: '15px',
                    pb: '15px'
                }}
            >
                <Typography
                    sx={{
                    
                        fontSize: '16px',
                        color: 'white',
                        textAlign: 'center',
                        mx: 'auto'
                    }}
                >
                    Please text or email us to arrange a call:
                </Typography>
               
                <Typography sx={{
                    textAlign: 'center',
                    mx: 'auto',
                    mt: '15px'
                }}>                        
                    <a 
                        style={{                        
                            color: 'white', 
                            textDecoration: 'none'
                        }} 
                        href="tel:+18182939445"
                    >
                        1 (818) 293 - 9445
                    </a>                               
                </Typography>
            </Box>

            <Box 
                sx={{
                    textAlign: 'center',
                    alignItems:'center',
                    justifyContent:'center',
                    maxWidth:'1200px',
                    m:'auto',
                    mt:'100px'
                }}
            >
                <Box>
                    <Typography fontSize='36px' color='white'>
                        Smoked Meats
                    </Typography>
                </Box>

                <Grid container px={2}>
                    <Grid
                        item 
                        xs={12} md={6} m='auto' p={2}
                        bgcolor='#1a1a1a' borderRadius={{md:'12px 0px 0px 0px', xs:'12px 12px 0px 0px'}}
                    >
                        <img src={Brisket} style={{ width: '100%', filter: 'drop-shadow(0px 8px 8px black)', borderRadius:'12px' }}/>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6} p={2}
                        bgcolor='#1a1a1a'  borderRadius={{md:'0px 12px 0px 0px', xs:'0px 0px 0px 0px'}}
                    >
                        <Typography  mb={2} sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }}>
                            Brisket
                        </Typography>
                        <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                            Dry rubbed and slow-smoked for 9-12 hours until the meat melts in your mouth. Comes with delicious sliced brisket flat and chopped brisket point.
                        </Typography>
                        <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '22px' }}>
                            $26 per guest
                        </Typography>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6}  p={2}
                        bgcolor='#2a2a2a'
                        sx={{ display: { xs: 'none', md: 'block' } }} 
                    >
                        <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }} mb={2}>
                            Tritip
                        </Typography>
                        <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                            Dry rubbed and slow-smoked for 2-3 hours to produce a lightly-smoky, tender, mouth-watering slices of tritip.
                        </Typography>
                        <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '22px' }}>
                            $20 per guest
                        </Typography>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6} p={2}
                        bgcolor='#2a2a2a'
                    >
                        <img src={Tritip} style={{ width: '100%', filter: 'drop-shadow(0px 8px 8px black)', borderRadius:'12px' }}/>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6} p={2}
                        bgcolor='#2a2a2a'
                        sx={{ display: { xs: 'block', md: 'none' } }}
                    >
                        <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }} mb={2}>
                            Tritip
                        </Typography>
                        <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                            Dry rubbed and slow-smoked for 2-3 hours to produce a lightly-smoky, tender, mouth-watering slices of tritip.
                        </Typography>
                        <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '22px' }}>
                            $20 per guest
                        </Typography>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6} m='auto' p={2}
                        bgcolor='#1a1a1a'  borderRadius={{md:'0px 0px 0px 12px', xs:'0px 0px 0px 0px'}}
                    >
                        <img src={PulledPork} style={{width:'100%', filter:'drop-shadow(0px 8px 8px black)', borderRadius:'12px' }}/>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6} p={2}
                        bgcolor='#1a1a1a'  borderRadius={{md:'0px 0px 12px 0px', xs:'0px 0px 12px 12px'}}
                    >
                        <Typography  mb={2} sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }}>
                            Pulled Pork
                        </Typography>
                        <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                            Dry rubbed pork shoulder, slow-smoked for 8-9 hours and topped with a slightly sweet barbecue sauce. It is cooked until it's tender and pulls apart easier than a fluffy doughnut.
                        </Typography>
                        <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '22px' }}>
                            $24 per guest
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Box 
                sx={{
                    textAlign: 'center',
                    alignItems:'center',
                    justifyContent:'center',
                    maxWidth:'1200px',
                    m:'auto',
                    mt:'100px'
                }}
            >
                <Box>
                    <Typography fontSize='36px' color='white'>
                        Grilled Meats
                    </Typography>
                </Box>

                <Grid container px={2} >
                    <Grid
                        item 
                        xs={12} md={6} m='auto' p={2}
                        bgcolor='#1a1a1a'  borderRadius={{md:'12px 0px 0px 0px', xs:'12px 12px 0px 0px'}}
                    >
                        <img src={FiletMignon} style={{ width: '100%', filter: 'drop-shadow(0px 8px 8px black)', borderRadius:'12px' }}/>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6} p={2}
                        bgcolor='#1a1a1a'   borderRadius={{md:'0px 12px 0px 0px', xs:'0px 0px 0px 0px'}}
                    >
                        <Typography  mb={2} sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }}>
                            Filet Mignon
                        </Typography>
                        <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                            Center cut filets, lightly seasoned and grilled to medium/ medium rare. Tender and juicy.
                        </Typography>
                        <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '22px' }}>
                            $25 per guest
                        </Typography>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6}  p={2}
                        bgcolor='#2a2a2a'
                        sx={{ display: { xs: 'none', md: 'block' } }}
                    >
                        <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }} mb={2}>
                            Pork Belly
                        </Typography>
                        <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                            Fatty pork belly grilled to a crispy exterior, juicy interior and well-rendered melty fat throughout.
                        </Typography>
                        <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '22px' }}>
                            $14 per guest
                        </Typography>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6} p={2}
                        bgcolor='#2a2a2a'
                    >
                        <img src={PorkBelly} style={{ width: '100%', filter: 'drop-shadow(0px 8px 8px black)', borderRadius:'12px' }}/>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6} p={2}
                        bgcolor='#2a2a2a'
                        sx={{ display: { xs: 'block', md: 'none' } }}
                    >
                        <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '18px' }} mb={2}>
                            Pork Belly
                        </Typography>
                        <Typography sx={{ color: 'white', textAlign: 'left' }} mb={2}>
                            Fatty pork belly grilled to a crispy exterior, juicy interior and well-rendered melty fat throughout.
                        </Typography>
                        <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '16px' }}>
                            $14 per guest
                        </Typography>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6} m='auto' p={2}
                        bgcolor='#1a1a1a' borderRadius={{md:'0px 0px 0px 12px', xs:'0px 0px 0px 0px'}}
                    >
                        <img src={ChickenBreast} style={{width:'100%', filter:'drop-shadow(0px 8px 8px black)', borderRadius:'12px'}}/>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6} p={2}
                        bgcolor='#1a1a1a'  borderRadius={{md:'0px 0px 12px 0px', xs:'0px 0px 12px 12px'}}
                    >
                        <Typography  mb={2} sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }}>
                            Chicken Breast
                        </Typography>
                        <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                            Slightly spicy, marinated chicken breast grilled until tender.
                        </Typography>
                        <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '22px' }}>
                            $10 per guest
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Box 
                sx={{
                    textAlign: 'center',
                    alignItems:'center',
                    justifyContent:'center',
                    maxWidth:'1200px',
                    m:'auto',
                    mt:'100px'
                }}
            >
                <Box>
                    <Typography fontSize='36px' color='white'>
                        Other Entrees
                    </Typography>
                </Box>

                <Grid container  px={2}>
                    <Grid
                        item 
                        xs={12} md={6} m='auto' p={2}
                        bgcolor='#2a2a2a' borderRadius={{md:'12px 0px 0px 0px', xs:'12px 12px 0px 0px'}}
                    >
                        <img src={SutDolma} style={{ width: '100%', filter: 'drop-shadow(0px 8px 8px black)', borderRadius:'12px' }}/>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6} p={2}
                        bgcolor='#2a2a2a'  borderRadius={{md:'0px 12px 0px 0px', xs:'0px 0px 0px 0px'}}
                    >
                        <Grid item xs={12}>
                            <img src={VeganIcon} style={{width:'60px', height:'60px'}}/>
                                
                            <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }} mb={2}>
                                Sut Dolma
                            </Typography>
                        </Grid>
                        <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                            Baked rice and herb stuffed sweet peppers.
                        </Typography>
                        <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '22px' }}>
                            $16 per guest
                        </Typography>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6}  p={2}
                        borderRadius={{md:'0px 0px 0px 12px', xs:'0px 0px 12px 12px'}}
                        bgcolor='#1a1a1a'
                        sx={{ display: { xs: 'none', md: 'block' }}} 
                    >
                        <Grid item xs={12}>
                            <img src={VeganIcon} style={{width:'60px', height:'60px'}}/>
                                
                            <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }} mb={2}>
                                Mediterranean Vegetables and Chickpeas
                            </Typography>
                        </Grid>
                        
                        
                        <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                            Sateed mushrooms, chickpeas,  bell peppers, and zuchini. A rich and flavorful vegan dining option.
                        </Typography>
                        <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '22px' }}>
                            $16 per guest
                        </Typography>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6} p={2}
                        borderRadius={{md:'0px 0px 12px 0px', xs:'0px 0px 0px 0px'}}
                        bgcolor='#1a1a1a'
                    >
                        <img src={MedetteraneanDish} style={{ width: '100%', filter: 'drop-shadow(0px 8px 8px black)', borderRadius:'12px' }}/>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6}  p={2}
                        borderRadius={{md:'0px 0px 0px 12px', xs:'0px 0px 12px 12px'}}
                        bgcolor='#1a1a1a'
                        sx={{ display: { xs: 'block', md: 'none' }}} 
                    >
                        <Grid item xs={12}>
                            <img src={VeganIcon} style={{width:'60px', height:'60px'}}/>
                                
                            <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }} mb={2}>
                                Mediterranean Vegetables and Chickpeas
                            </Typography>
                        </Grid>
                        <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                            Sateed mushrooms, chickpeas,  bell peppers, and zuchini. A rich and flavorful vegan dining option.
                        </Typography>
                        <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '22px' }}>
                            $16 per guest
                        </Typography>
                    </Grid>
                    
                </Grid>
            </Box>

            <Box 
                sx={{
                    textAlign: 'center',
                    alignItems:'center',
                    justifyContent:'center',
                    maxWidth:'1200px',
                    m:'auto',
                    mt:'100px'
                }}
            >
                <Box>
                    <Typography fontSize='36px' color='white'>
                        Sides
                    </Typography>
                </Box>

                <Grid container px={2} >
                    <Grid
                        item 
                        xs={12} md={6} m='auto' p={2}
                        bgcolor='#2a2a2a'  borderRadius={{md:'12px 0px 0px 0px', xs:'12px 12px 0px 0px'}}
                    >
                        <img src={Tzatziki} style={{ width: '100%', filter: 'drop-shadow(0px 8px 8px black)', borderRadius:'12px' }}/>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6} p={2}
                        bgcolor='#2a2a2a'   borderRadius={{md:'0px 12px 0px 0px', xs:'0px 0px 0px 0px'}}
                    >
                        <Typography  mb={2} sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }}>
                            Tzatziki
                        </Typography>
                        <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                            A cool yogurt and cucumber based salad that goes great with almost any meat.
                        </Typography>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6}  p={2}
                        bgcolor='#1a1a1a'
                        sx={{ display: { xs: 'none', md: 'block' } }}
                    >
                        <Grid item xs={12}>
                            <img src={VeganIcon} style={{width:'60px', height:'60px'}}/>
                                
                            <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }} mb={2}>
                                Cabbage Salad
                            </Typography>
                        </Grid>
                        <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                            A refreshing, slightly tart cabbage and carrot salad.
                        </Typography>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6} p={2}
                        bgcolor='#1a1a1a'
                    >
                        <img src={CabbageSalad} style={{ width: '100%', filter: 'drop-shadow(0px 8px 8px black)', borderRadius:'12px' }}/>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6}  p={2}
                        bgcolor='#1a1a1a'
                        sx={{ display: { xs: 'block', md: 'none' } }}
                    >
                        <Grid item xs={12}>
                            <img src={VeganIcon} style={{width:'60px', height:'60px'}}/>
                                
                            <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }} mb={2}>
                               Cabbage Salad
                            </Typography>
                        </Grid>
                        <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                            A refreshing, slightly tart cabbage and carrot salad.
                        </Typography>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6} m='auto' p={2}
                        bgcolor='#2a2a2a'
                        //  borderRadius={{md:'0px 0px 0px 12px', xs:'0px 0px 0px 0px'}}
                    >
                        <img src={Hummus} style={{width:'90%', filter:'drop-shadow(0px 8px 8px black)', borderRadius:'12px'}}/>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6} p={2}
                        bgcolor='#2a2a2a' 
                        //sx={{ display: { xs: 'block', md: 'none' } }}
                    >
                        <Grid item xs={12}>
                            <img src={VeganIcon} style={{width:'60px', height:'60px'}}/>
                                
                            <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }} mb={2}>
                                Hummus
                            </Typography>
                        </Grid>
                        <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                            Creamy, mashed chicked peas that go well as a dip or side.
                        </Typography>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6}  p={2}
                        bgcolor='#1a1a1a'
                        borderRadius={{md:'0px 0px 0px 12px', xs:'0px 0px 0px 12px'}}
                        sx={{ display: { xs: 'none', md: 'block' } }}
                    >
                        <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }} mb={2}>
                            Rice
                        </Typography>
                        <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                           Round, fluffy white rice that is a fantastic accompaniment to everything on the menu.
                        </Typography>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6} p={2}
                        bgcolor='#1a1a1a'
                        borderRadius={{md:'0px 0px 12px 0px', xs:'0px 0px 0px 0px'}}
                    >
                        <img src={Rice} style={{ width: '100%', filter: 'drop-shadow(0px 8px 8px black)', borderRadius:'12px' }}/>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={12}  p={2}
                        bgcolor='#1a1a1a' borderRadius={{md:'0px 0px 0px 12px', xs:'0px 0px 12px 12px'}}
                        sx={{ display: { xs: 'block', md: 'none' } }}
                    >
                        <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }} mb={2}>
                            Rice
                        </Typography>
                        <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                           Round, fluffy white rice that is a fantastic accompaniment to everything on the menu.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>            

            <Box sx={{
                color: 'white',
                textAlign: 'center'
            }}>
                <Box
                 sx={{
                    mt: '1.5%',
                    mb: '1.5%',
                }}>
                    <Typography
                    sx={{ 
                        fontSize: '52px'
                    }} >
                        Catering Menu
                    </Typography>
                    <Typography 
                    sx={{ 
                        fontSize: '40px',
                        mt: '0.5%',
                        mb: '0.5%'
                    }} 
                    >
                        Combinations
                    </Typography>
                    <Typography  
                        sx={{ 
                            color: 'white',
                            fontSize: '23px'
                            }} 
                        >
                        Grilled Meat Trio
                    </Typography>
                    <Stack 
                        spacing={2}
                    >
                        <List 
                        sx={{                    
                        alignSelf: 'center',                    
                        }}>                        
                            <ListItem 
                            sx={{
                                marginInlineStart: 0,
                                pt: '0px',
                                pb: '0px',
                                textAlign: 'left'
                            }}>
                                <ArrowRightIcon sx={{ color: 'white'}} />
                                All Grilled Meats
                            </ListItem>
                            <ListItem
                            sx={{
                                pt: '0px',
                                pb: '0px',
                                textAlign: 'left'
                            }}>
                            <ArrowRightIcon sx={{ color: 'white'}} />
                                Sides & Rice
                            </ListItem>
                            <ListItem
                            sx={{
                                pt: '0px',
                                pb: '0px',
                                textAlign: 'left'
                            }}>
                            <ArrowRightIcon sx={{ color: 'white'}} />
                                $26 per guest
                            </ListItem>
                        </List>
                    </Stack>
                </Box>
                
                <Box
                 sx={{
                    mt: '1.5%',
                    mb: '1.5%',
                }}>
                    <Typography  
                        sx={{ 
                            color: 'white',
                            fontSize: '23px'
                        }} 
                    >
                        Smoked Meat Trio
                    </Typography>
                    <Stack spacing={2}>
                        <List 
                            sx={{                    
                                alignSelf: 'center',                    
                            }}
                        >                        
                            <ListItem 
                            sx={{
                                pt: '0px',
                                pb: '0px',
                                textAlign: 'left'
                            }}>
                                <ArrowRightIcon sx={{ color: 'white'}} />
                                All Smoked Meats
                            </ListItem>                            
                            <ListItem
                            sx={{
                                pt: '0px',
                                pb: '0px',
                                textAlign: 'left'
                            }}>
                            <ArrowRightIcon sx={{ color: 'white'}} />
                                Sides & Rice
                            </ListItem>
                            <ListItem
                            sx={{
                                pt: '0px',
                                pb: '0px',
                                textAlign: 'left'
                            }}>
                            <ArrowRightIcon sx={{ color: 'white'}} />
                                $34 per guest
                            </ListItem>
                        </List>
                    </Stack>
                </Box>

                <Box 
                sx={{
                    mt: '1.5%',
                    mb: '1.5%',
                }}>
                    <Typography  
                        sx={{ 
                            color: 'white',
                            fontSize: '23px'
                            }} 
                        >
                        All Beef Platter
                    </Typography>
                    <Stack spacing={1}>
                        <List 
                        sx={{                    
                        alignSelf: 'center',                    
                        }}>                        
                            <ListItem 
                            sx={{
                                marginInlineStart: 0,
                                pt: '0px',
                                pb: '0px'
                            }}>
                                <ArrowRightIcon sx={{ color: 'white'}} />
                                Filet Mignon
                            </ListItem>
                            <ListItem 
                            sx={{
                                marginInlineStart: 0,
                                pt: '0px',
                                pb: '0px'
                            }}>
                                <ArrowRightIcon sx={{ color: 'white'}} />
                                Tri Tip
                            </ListItem>
                            <ListItem 
                            sx={{
                                marginInlineStart: 0,
                                pt: '0px',
                                pb: '0px'
                            }}>
                                <ArrowRightIcon sx={{ color: 'white'}} />
                                Brisket
                            </ListItem>
                            <ListItem
                            sx={{
                                pt: '0px',
                                pb: '0px'
                            }}>
                            <ArrowRightIcon sx={{ color: 'white'}} />
                                Sides & Rice
                            </ListItem>
                            <ListItem
                            sx={{
                                pt: '0px',
                                pb: '0px'
                            }}>
                            <ArrowRightIcon sx={{ color: 'white'}} />
                                $42 per guest
                            </ListItem>
                        </List>
                    </Stack>
                </Box>
                <Box
                 sx={{
                    mt: '1.5%',
                    mb: '1.5%',
                }}>
                    <Typography  
                        sx={{ 
                            color: 'white',
                            fontSize: '23px'
                            }} 
                        >
                        No Beef Platter
                    </Typography>
                    <Stack spacing={2}>
                        <List 
                        sx={{                    
                        alignSelf: 'center',                    
                        }}>                        
                            <ListItem 
                            sx={{
                                marginInlineStart: 0,
                                pt: '0px',
                                pb: '0px'
                            }}>
                                <ArrowRightIcon sx={{ color: 'white'}} />
                                Chicken Breast
                            </ListItem>
                            <ListItem 
                            sx={{
                                marginInlineStart: 0,
                                pt: '0px',
                                pb: '0px'
                            }}>
                                <ArrowRightIcon sx={{ color: 'white'}} />
                                Pork Belly
                            </ListItem>
                            <ListItem 
                            sx={{
                                marginInlineStart: 0,
                                pt: '0px',
                                pb: '0px'
                            }}>
                                <ArrowRightIcon sx={{ color: 'white'}} />
                                Pulled Pork
                            </ListItem>
                            <ListItem
                            sx={{
                                pt: '0px',
                                pb: '0px'
                            }}>
                            <ArrowRightIcon sx={{ color: 'white'}} />
                                Sides & Rice
                            </ListItem>
                            <ListItem
                            sx={{
                                pt: '0px',
                                pb: '0px'
                            }}>
                            <ArrowRightIcon sx={{ color: 'white'}} />
                                $28 per guest
                            </ListItem>
                        </List>
                    </Stack>

                    <Typography  
                        sx={{ 
                            color: 'white',
                            fontSize: '23px'
                            }} 
                        >
                        Vegan Delight
                    </Typography>
                    <Stack spacing={2} sx={{
                        
                    }}>
                        <List 
                        sx={{                    
                        alignSelf: 'center',                    
                        }}>                        
                            <ListItem 
                            sx={{
                                marginInlineStart: 0,
                                pt: '0px',
                                pb: '0px'
                            }}>
                                <ArrowRightIcon sx={{ color: 'white'}} />
                                Sut Dolma
                            </ListItem>
                            <ListItem 
                            sx={{
                                marginInlineStart: 0,
                                pt: '0px',
                                pb: '0px',
                            }}>
                                <ArrowRightIcon sx={{ color: 'white'}} />
                                Medi. Veggies & <br/> Chickpeas
                            </ListItem>     
                            <ListItem
                            sx={{
                                pt: '0px',
                                pb: '0px'
                            }}>
                            <ArrowRightIcon sx={{ color: 'white'}} />
                                Sides & Rice
                            </ListItem>
                            <ListItem
                            sx={{
                                pt: '0px',
                                pb: '0px'
                            }}>
                            <ArrowRightIcon sx={{ color: 'white'}} />
                                $26 per guest
                            </ListItem>
                        </List>
                    </Stack>

                </Box>
            </Box>

            <Box sx={{
                color: 'white',
                textAlign: 'center'
            }}>
                <Typography
                sx={{
                    fontSize: "38px"
                }}>
                    All Grilled and Smoked Meats
                </Typography>
                <Typography
                sx={{
                    fontSize: "22px"
                }}>
                    $50 per guest
                </Typography>
                <Typography
                sx={{
                    mt: '30px'
                }}>
                    For any custom catering please reach out to us via email 
                </Typography>
                <Typography>
                    using the contact form on the bottom of the page.
                </Typography>
                <Typography
                sx={{
                    mt: '30px'
                }}>
                    You can also text us.
                </Typography>
            </Box>

            <Box  
                mt={4 }
                sx={{ 
                    mx: 'auto', 
                    textAlign: 'center', 
                }}
            >
                <Typography fontSize={32} color='white' mb={4}>
                    Follow us on Instagram
                </Typography>

                <IconButton
                  size="large"
                //   onClick={()=>console.log('to instagram')}
                  href='https://www.instagram.com/charcoalcraze/'  rel='norefferer noopener' target="_blank"
                  sx={{
                    
                    transition: "all 0.3s ease-in-out",
                    opacity: 0.7,
                    height: '100px',
                    width: '100px',
                    ":hover": { opacity: 1 },
                  }}
                >
                  <img src={InstagramIcon} style={{height:'100px', width:'100px'}}/>
                </IconButton>
            </Box>
            {/* Section 3: Catering Calendar */}
            <Box>
                <Box id='bookEvent'>
                    <Typography 
                        sx={{ 
                            color: '#FFFFFF', 
                            textAlign: 'center', 
                            mt: '50px', 
                            fontSize: '32px',
                            px: '5%'
                        }}
                    >
                        Want delicious Armenian / American Fusion BBQ catered at your event?
                    </Typography>
                </Box>         
            </Box>
            {/* Section 4: Contact Form */}         
            <Box 
                sx={{ 
                    mx: 'auto', 
                    textAlign: 'center', 
                    mt: '40px'
                }}
            >
                <Typography 
                    sx={{ 
                        fontSize: '28px', 
                        color: 'white'
                    }}
                >
                    Contact Us
                </Typography>    
                <EmailRestAPI />
            </Box> 
            {/* Section 5: Footer */}
            <Box 
                sx={{ 
                    textAlign: 'center',
                    mt: '65px',
                    mb: '25px'
                }}
            >
                <Typography 
                    sx={{ 
                        color: 'white',
                        mx: 'auto'
                    }}
                >
                    Copyright © Charcoal Craze LLC 2024
                </Typography>
                <Typography sx={{ mx: '5px'}}>                        
                    <a 
                        style={{                        
                            color: 'white', 
                            textDecoration: 'none'
                        }} 
                        href="tel:+18182939445"
                    >
                        Phone: +1 818 293 9445
                    </a>                               
                </Typography>
            </Box>
        </Box>
    )
}

export default LandingPage