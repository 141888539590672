import React from 'react';
import { Box, Grid, Table, TableBody, TableHead, TableRow, Typography } from '@mui/material';
import { VendingInfo } from './vendingTypes';
import { NavLink } from 'react-router-dom';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { Place } from '@mui/icons-material';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.grey[400],
      fontSize: 'clamp(12px,12px + 1vw, 18px)',
      
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 'clamp(12px,12px + 1vw, 18px)',
      color: 'white'
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    transition: 'all .2s ease-in-out',
    '&:hover':{
        backgroundColor: '#000'
    },
    '&:nth-of-type(even)': {
        backgroundColor: 'rgba(255,255,255,0.1)',
        '&:hover':{
            backgroundColor: 'rgba(255,255,255,0.2)',
        } 
    },
    // hide last border
    '& td': {
      border: 0,
    },
  }));

const VendingTable = ({data}: {data:VendingInfo[]}) => {
 
  return (
    <Box sx={{
      textAlign: 'center',
      alignItems:'center',
      justifyContent:'center',
      maxWidth:'800px',
      m:'auto',
      color: 'white'
    }}>
        <Table  >
            <TableHead>
                <TableRow>
                    <StyledTableCell sx={{color:'white', fontSize:'18px'}}>Date</StyledTableCell>
                    <StyledTableCell sx={{color:'white', fontSize:'18px'}}>Time</StyledTableCell>
                    <StyledTableCell sx={{color:'white', fontSize:'18px'}}>Location</StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody className='tables'>
                {data.map(serviceData => 
                    <StyledTableRow key={serviceData.date}>
                        <StyledTableCell >
                            { dayjs(serviceData.date).format("dddd MMMM Do, YYYY")}
                        </StyledTableCell>
                        <StyledTableCell >
                            {serviceData.serviceTime}
                        </StyledTableCell>
                        <StyledTableCell >
                            <NavLink to={serviceData.mapLink} className='nav-styles'>
                                <Grid container>
                                    <Grid item xs={2} sm={1}>
                                        <Place/> 
                                    </Grid>
                                    <Grid item xs={10} sm={11}>
                                        {serviceData.location}
                                    </Grid>
                                </Grid>
                            </NavLink>
                        </StyledTableCell>
                    </StyledTableRow>
                )}
            </TableBody>
        </Table>

    </Box>
  );
};

export default VendingTable;
