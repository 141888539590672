import { Grid, Box, Typography, Paper } from "@mui/material";
import Link from "@mui/material/Link";
import ZeroStars from '../../assets/yelp-assets/Review_Ribbon_small_16_0@2x.png'
import HalfStars from '../../assets/yelp-assets/Review_Ribbon_small_16_half@2x.png'
import OneStars from '../../assets/yelp-assets/Review_Ribbon_small_16_1@2x.png'
import OneAndHalfStars from '../../assets/yelp-assets/Review_Ribbon_small_16_1_half@2x.png'
import TwoStars from '../../assets/yelp-assets/Review_Ribbon_small_16_2@2x.png'
import TwoAndHalfStars from '../../assets/yelp-assets/Review_Ribbon_small_16_2_half@2x.png'
import ThreeStars from '../../assets/yelp-assets/Review_Ribbon_small_16_3@2x.png'
import ThreeAndHalfStars from '../../assets/yelp-assets/Review_Ribbon_small_16_3_half@2x.png'
import FourStars from '../../assets/yelp-assets/Review_Ribbon_small_16_4@2x.png'
import FourAndHalfStars from '../../assets/yelp-assets/Review_Ribbon_small_16_4_half@2x.png'
import FiveStars from '../../assets/yelp-assets/Review_Ribbon_small_16_5@2x.png'
import YelpLogo from '../../assets/yelp-assets/yelp_logo.png'
import { Review, UserDetails, CCReview } from "./yelpReview";
import NoProfileIcon from '../../assets/yelp-assets/no-profile-pic.png'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


// interface RatingImage {
//     [key:number]: string
// }

// const RatingImages: RatingImage[] = [
//     {0: ZeroStars},
//     {0.5: HalfStars},
//     {1: OneStars},
//     {1.5: OneAndHalfStars},
//     {2: TwoStars},
//     {2.5: TwoAndHalfStars},
//     {3: ThreeStars},
//     {3.5: ThreeAndHalfStars},
//     {4: FourStars},
//     {4.5: FourAndHalfStars},
//     {5: FiveStars}

// ]

const ratingMap = new Map<number,string>([
    [0, ZeroStars],
    [0.5, HalfStars],
    [1, OneStars],
    [1.5, OneAndHalfStars],
    [2, TwoStars],
    [2.5, TwoAndHalfStars],
    [3, ThreeStars],
    [3.5, ThreeAndHalfStars],
    [4, FourStars],
    [4.5, FourAndHalfStars],
    [5, FiveStars]
])

{/* <span class="yelp-review" data-review-id="-VbIyqE4-SBmWxZ8YBsVlA" data-hostname="www.yelp.com">Read <a href="https://www.yelp.com/user_details?userid=9jh8yzbnT4kIFDpbzbue6A" rel="nofollow noopener">Jessica R.</a>'s <a href="https://www.yelp.com/biz/charcoal-craze-glendale?hrid=-VbIyqE4-SBmWxZ8YBsVlA" rel="nofollow noopener">review</a> of <a href="https://www.yelp.com/biz/YuzfrLFr4Tnl6nQerMgIEw" rel="nofollow noopener">Charcoal Craze</a> on <a href="https://www.yelp.com" rel="nofollow noopener">Yelp</a><script src="https://www.yelp.com/embed/widgets.js" type="text/javascript" async></script></span> */}

const mockReviews = `{
    "reviews": [
      {
        "id": "INUZEANxWUtgBfE2R93_Pw",
        "url": "https://www.yelp.com/biz/charcoal-craze-glendale?adjust_creative=G7LmzMTBn7x0h6ZXp86VcA&hrid=INUZEANxWUtgBfE2R93_Pw&utm_campaign=yelp_api_v3&utm_medium=api_v3_business_reviews&utm_source=G7LmzMTBn7x0h6ZXp86VcA",
        "text": "The food is soooo good.   I've had the smoked tri tip,  pulled pork,  chicken and brisket and all of it is amazing.  Love the Greek twist with the yogurt,...",
        "rating": 5,
        "time_created": "2024-12-28 13:25:20",
        "user": {
          "id": "tZTcjvLj5AnWyln7CtEPkA",
          "profile_url": "https://www.yelp.com/user_details?userid=tZTcjvLj5AnWyln7CtEPkA",
          "image_url": "https://s3-media3.fl.yelpcdn.com/photo/8GXLBBN8cIUXRbWmRO6ngw/o.jpg",
          "name": "Todd V."
        }
      },
      {
        "id": "-VbIyqE4-SBmWxZ8YBsVlA",
        "url": "https://www.yelp.com/biz/charcoal-craze-glendale?adjust_creative=G7LmzMTBn7x0h6ZXp86VcA&hrid=-VbIyqE4-SBmWxZ8YBsVlA&utm_campaign=yelp_api_v3&utm_medium=api_v3_business_reviews&utm_source=G7LmzMTBn7x0h6ZXp86VcA",
        "text": "I recently ordered the pulled pork and it was fantastic. The meat was incredibly tender and flavorful. I was also impressed by the friendly cooks. They were very welcoming and helpful...",
        "rating": 5,
        "time_created": "2025-01-19 20:35:10",
        "user": {
          "id": "9jh8yzbnT4kIFDpbzbue6A",
          "profile_url": "https://www.yelp.com/user_details?userid=9jh8yzbnT4kIFDpbzbue6A",
          "image_url": null,
          "name": "Jessica R."
        }
      },
      {
        "id": "1FKs5auuKiukAroy0Ulnww",
        "url": "https://www.yelp.com/not_recommended_reviews/charcoal-craze-glendale",
        "text": "Charcoal Craze makes amazing brisket! I had it catered and it was so good, I later drove 45 minutes to pick one up for a family event. That one I reheated...",
        "rating": 5,
        "time_created": "2024-12-29 13:04:41",
        "user": {
          "id": "VUpMycz7UQfmHU7a2nh98A",
          "profile_url": "https://www.yelp.com/user_details?userid=VUpMycz7UQfmHU7a2nh98A",
          "image_url": null,
          "name": "David H."
        }
      }
    ],
    "total": 3,
    "possible_languages": [
      "en"
    ]
}`

const mockBusinessInfo = {
    "id": "YuzfrLFr4Tnl6nQerMgIEw",
    "alias": "charcoal-craze-glendale",
    "name": "Charcoal Craze",
    "image_url": "https://s3-media1.fl.yelpcdn.com/bphoto/0ThaS15ps57WXKaWkWA3Tw/o.jpg",
    "is_claimed": true,
    "is_closed": false,
    "url": "https://www.yelp.com/biz/charcoal-craze-glendale?adjust_creative=G7LmzMTBn7x0h6ZXp86VcA&utm_campaign=yelp_api_v3&utm_medium=api_v3_business_lookup&utm_source=G7LmzMTBn7x0h6ZXp86VcA",
    "phone": "+18182939445",
    "display_phone": "(818) 293-9445",
    "review_count": 2,
    "categories": [
        {
        "alias": "foodstands",
        "title": "Food Stands"
        },
        {
        "alias": "catering",
        "title": "Caterers"
        }
    ],
    "rating": 5,
    "location": {
        "address1": "1300 Flower St",
        "address2": null,
        "address3": "",
        "city": "Glendale",
        "zip_code": "91201",
        "country": "US",
        "state": "CA",
        "display_address": [
        "1300 Flower St",
        "Glendale, CA 91201"
        ],
        "cross_streets": ""
    },
    "coordinates": {
        "latitude": 34.160728687522074,
        "longitude": -118.28887893560264
    },
    "photos": [
        "https://s3-media1.fl.yelpcdn.com/bphoto/0ThaS15ps57WXKaWkWA3Tw/o.jpg",
        "https://s3-media2.fl.yelpcdn.com/bphoto/XoIfkc6IUQdlShml-5FQjg/o.jpg",
        "https://s3-media3.fl.yelpcdn.com/bphoto/o53FRew2ycJLnyRS_JIaiQ/o.jpg"
    ],
    "hours": [
        {
        "open": [
            {
            "is_overnight": false,
            "start": "1000",
            "end": "2100",
            "day": 0
            },
            {
            "is_overnight": false,
            "start": "1100",
            "end": "2100",
            "day": 2
            },
            {
            "is_overnight": false,
            "start": "1100",
            "end": "2100",
            "day": 4
            },
            {
            "is_overnight": false,
            "start": "1100",
            "end": "2100",
            "day": 5
            }
        ],
        "hours_type": "REGULAR",
        "is_open_now": false
        }
    ],
    "attributes": {
        "menu_url": "https://charcoalcraze.com/menu"
    },
    "transactions": [],
    "messaging": {
        "url": "https://www.yelp.com/raq/YuzfrLFr4Tnl6nQerMgIEw?adjust_creative=G7LmzMTBn7x0h6ZXp86VcA&utm_campaign=yelp_api_v3&utm_medium=api_v3_business_lookup&utm_source=G7LmzMTBn7x0h6ZXp86VcA#popup%3Araq",
        "use_case_text": "Message the Business"
    }
}


const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

const UserReview  = ({review}:{review:Review}) => {
    var dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return(

        
            <Grid container bgcolor='#2a2a2a' p={2} borderRadius={2} height='100%' sx={{filter:'drop-shadow(0px 4px 2px rgba(0,0,0,.8))'}}>

                <Grid item xs={4}>
                    <img src={review.user.image_url? review.user.image_url: NoProfileIcon} style={{ width: '80px', height:'80px', filter: 'drop-shadow(0px 8px 8px black)', borderRadius:'12px' }}/>
                </Grid>
                <Grid item xs={8} m='auto'>

                    <Typography sx={{ color: 'white', fontWeight: 'bold', fontSize: '16px' }} >
                        {review.user.name}
                    </Typography>
                </Grid>
                

                    <Grid container mt={2} justifyContent='space-between' alignItems='center'>
                            
                        <Grid item xs={5} >
                            <img src={ratingMap.get(review.rating)} style={{width:'100%', height:'auto'}}/>
                        </Grid>
                        <Grid item xs={7} textAlign='right'>
                            <Typography sx={{ color: 'white',  fontSize: '14px' }}>
                                {
                                    new Date(review.time_created).toLocaleDateString()
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item mt={2} textAlign='left'>
                        <Typography sx={{ color: 'white',  fontSize: '14px' }}>
                            {
                                review.text
                            }
                        </Typography>
                    </Grid>
                    <Grid container mt={2} justifyContent='right'>
                        <Link  href={`${review.url}`} target="_blank" rel="noopener noreferrer">
                            <img src={YelpLogo} style={{height:'40px'}}/>
                        </Link>
                    </Grid>
            </Grid>
      
           

    )
}

export const YelpReviewWidget = () => {

     const yelpReviews: Review [] = JSON.parse(mockReviews).reviews

     return(
        <Box width='85%' position='relative' margin='auto'>
            
                
            <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={responsive}
                ssr={false} // means to render carousel on server-side.
                infinite={false}
                autoPlay={false}
                autoPlaySpeed={2000}
                keyBoardControl={true}
                customTransition="all 500ms ease-in-out"
                transitionDuration={500}
                itemClass="yelp-slide"
                containerClass="yelp-carousel"
                // removeArrowOnDeviceType={["tablet", "mobile"]}
                // deviceType={this.props.deviceType}
                dotListClass="dot-list-style"
               
            >
                {yelpReviews.map( review =>
                <Grid container justifyContent='center' p={2} height='100%' key={review.id}>
                    <Grid key={review.id} item height='100%'>
                        <UserReview review={review} />
                    </Grid>
                </Grid>
                )

                }
            </Carousel>
                
                
           
        </Box>
     )
}