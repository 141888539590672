import Coke from '../assets/coke.webp'
import Sprite from '../assets/sprite.webp'
import Water from '../assets/water.webp'
import ComingSoon from '../assets/coming-soon.webp'
import Brisket from '../assets/brisket.webp'
import CabbageSalad from '../assets/cabbageSalad.webp'
import ChickenBreast from '../assets/chickenBreast.webp'
import FiletMignon from '../assets/filetMignon.webp'
import Hummus from '../assets/hummus.webp'
import PorkBelly from '../assets/porkBelly.webp'
import PulledPork from '../assets/pulledPork.webp'
import Tritip from '../assets/tritip.webp'
import Tzatziki from '../assets/tzatziki.webp'

export enum ItemIds {
  FiletMignon,
  ChickenBreast,
  PorkBelly,
  Brisket,
  Tritip, 
  PulledPork,
  Tzatziki,
  CabageSalad
}

export type Item = {
    id: number,
    img: string,
    title: string,
    description: string,
    nutritionFacts: NutritionFacts,
}

export interface NutritionFacts {
  calories : number,
  totalFat : number,
  saturatedFat : number,
  transfat: number,
  polyUnsaturatedFat: number,
  monoUnsaturatedFat: number,
  cholesterol : number,  
  sodium: number,
  totalCarbs : number,
  dietaryFiber: number,
  sugar: number,
  protein: number,
  vitaminD: number,
  calcium: number,
  iron: number,
  potassium : number,
}

export const grilledMeatsData: Item[] = [
    {
      id: 1,
      img: FiletMignon,
      title: 'Filet Mignon',      
      nutritionFacts:  {
        calories: 605,
        totalFat: 40,
        saturatedFat: 15.2,
        transfat: 0,
        polyUnsaturatedFat: 1.5,
        monoUnsaturatedFat: 16.3,
        cholesterol: 217.9,
        sodium: 122.7,
        totalCarbs: 0,
        dietaryFiber: 0,
        sugar: 0,
        protein: 58.7,
        vitaminD: 0.7,
        calcium: 42.7,
        iron: 3.7,
        potassium: 745.6,
      },
      description: ''
    },
    {
      id: 2,
      img: ChickenBreast,
      title: 'Marinated Chicken Breast Skewer',
      nutritionFacts: {
        calories: 382,
        totalFat: 8,
        saturatedFat: 2,
        transfat: 0,
        polyUnsaturatedFat: 1.3,
        monoUnsaturatedFat: 2.6,
        cholesterol: 195,
        sodium: 170,
        totalCarbs: 0,
        dietaryFiber: 0,
        sugar: 0,
        protein: 70,
        vitaminD: 0.2,
        calcium: 30,
        iron: 2.1,
        potassium: 610.3
      },
      description: ''
    },
    {
      id: 3,
      img: PorkBelly,
      title: 'Pork Belly Skewer',
      nutritionFacts: {
        calories: 664,
        totalFat: 48.7,
        saturatedFat: 17.6,
        transfat: 0.4,
        polyUnsaturatedFat: 8,
        monoUnsaturatedFat: 20.8,
        cholesterol: 192,
        sodium: 216,
        totalCarbs: 0,
        dietaryFiber: 0,
        sugar: 0,
        protein: 52,
        vitaminD: 0,
        calcium: 0,
        iron: 0,
        potassium: 52
      },
      description: ''
    },
  ];

  export const smokedMeatsData: Item[] = [
    {
      id: 1,
      img: Brisket,
      title: 'Brisket',      
      nutritionFacts:  {
        calories: 655,
        totalFat: 42.7,
        saturatedFat: 16.5,
        transfat: 0,
        polyUnsaturatedFat: 1.5,
        monoUnsaturatedFat: 17.9,
        cholesterol: 240,
        sodium: 109.3,
        totalCarbs: 0,
        dietaryFiber: 0,
        sugar: 0,
        protein: 64,
        vitaminD: 0,
        calcium: 37.3,
        iron: 5.6,
        potassium: 525.9,
      },
      description: ''
    },
    {
      id: 2,
      img: Tritip,
      title: 'Tritip',
      nutritionFacts: {
        calories: 394.7,
        totalFat: 21.5,
        saturatedFat: 8,
        transfat: 0,
        polyUnsaturatedFat: 1,
        monoUnsaturatedFat: 10.7,
        cholesterol: 154.8,
        sodium: 114.7,
        totalCarbs: 0,
        dietaryFiber: 0,
        sugar: 0,
        protein: 48,
        vitaminD: 0,
        calcium: 56,
        iron: 3.2,
        potassium: 709.5
      },
      description: ''
    },
    {
      id: 3,
      img: PulledPork,
      title: 'Pulled Pork',
      nutritionFacts: {
        calories: 421.3,
        totalFat: 29.3,
        saturatedFat: 9.9,
        transfat: 0.3,
        polyUnsaturatedFat: 3.7,
        monoUnsaturatedFat: 11.7,
        cholesterol: 141.3,
        sodium: 138.7,
        totalCarbs: 0,
        dietaryFiber: 0,
        sugar: 0,
        protein: 40,
        vitaminD: 0,
        calcium: 91,
        iron: 0,
        potassium: 720.8
      },
      description: ''
    },
  ];
  
  export const sidesData: Item[] = [   
    {
        id: 1,
        img: Tzatziki,
        title: 'Tzatziki',
        nutritionFacts: {
          calories: 54,
          totalFat: 4.4,
          saturatedFat: 1.2,
          transfat: 0,
          polyUnsaturatedFat: 0.4,
          monoUnsaturatedFat: 2.6,
          cholesterol: 4.6,
          sodium: 110,
          totalCarbs: 2.6,
          dietaryFiber: 0.3,
          sugar: 2,
          protein: 1.5,
          vitaminD: 0,
          calcium: 64,
          iron: 0.2,
          potassium: 93.5
        },
        description: "",
    },
    {
        id: 2,
        img: CabbageSalad,
        title: 'Cabbage Salad',
        nutritionFacts: {
          calories: 40,
          totalFat: 0,
          saturatedFat: 0,
          transfat: 0,
          polyUnsaturatedFat: 0,
          monoUnsaturatedFat: 0,
          cholesterol: 0,
          sodium: 420,
          totalCarbs: 6,
          dietaryFiber: 3,
          sugar: 3,
          protein: 1,
          vitaminD: 0,
          calcium: 40,
          iron: 1,
          potassium: 0
        },
        description: '',
    },
  ];
  
  export const drinksDessertsData: Item[] = [
      {
        id: 1,
        img: Coke,
        title: 'Coke',
        nutritionFacts: 
        {
          calories: 140,
          totalFat: 0,
          saturatedFat: 0,
          transfat: 0,
          polyUnsaturatedFat: 0,
          monoUnsaturatedFat: 0,
          cholesterol: 0,
          sodium: 45,
          totalCarbs: 39,
          dietaryFiber: 0,
          sugar: 39,
          protein: 0,
          vitaminD: 0,
          calcium: 0,
          iron: 0,
          potassium: 0
        },
        description: '',
      },
      {
        id: 2,
        img: Sprite,
        title: 'Sprite',
        nutritionFacts:        
        {
          calories: 140,
          totalFat: 0,
          saturatedFat: 0,
          transfat: 0,
          polyUnsaturatedFat: 0,
          monoUnsaturatedFat: 0,
          cholesterol: 0,
          sodium: 65,
          totalCarbs: 38,
          dietaryFiber: 0,
          sugar: 39,
          protein: 0,
          vitaminD: 0,
          calcium: 0,
          iron: 0,
          potassium: 0
        },
        description: '',
      },      
      {
        id: 3,
        img: Water,
        title: 'Water',
        nutritionFacts:        
        {
          calories: 0,
          totalFat: 0,
          saturatedFat: 0,
          transfat: 0,
          polyUnsaturatedFat: 0,
          monoUnsaturatedFat: 0,
          cholesterol: 0,
          sodium: 0,
          totalCarbs: 0,
          dietaryFiber: 0,
          sugar: 0,
          protein: 0,
          vitaminD: 0,
          calcium: 0,
          iron: 0,
          potassium: 0
        },
        description: '',
      },   
    ];

    export const veganEntreeData: Item[] = [
      {
        id: 1,
        img: Coke,
        title: 'Sut Dolma',
        nutritionFacts: 
        {
          calories: 140,
          totalFat: 0,
          saturatedFat: 0,
          transfat: 0,
          polyUnsaturatedFat: 0,
          monoUnsaturatedFat: 0,
          cholesterol: 0,
          sodium: 45,
          totalCarbs: 39,
          dietaryFiber: 0,
          sugar: 39,
          protein: 0,
          vitaminD: 0,
          calcium: 0,
          iron: 0,
          potassium: 0
        },
        description: '',
      },
      {
        id: 2,
        img: Sprite,
        title: 'Mediterranean Vegetables & Chickpeas',
        nutritionFacts:        
        {
          calories: 140,
          totalFat: 0,
          saturatedFat: 0,
          transfat: 0,
          polyUnsaturatedFat: 0,
          monoUnsaturatedFat: 0,
          cholesterol: 0,
          sodium: 65,
          totalCarbs: 38,
          dietaryFiber: 0,
          sugar: 39,
          protein: 0,
          vitaminD: 0,
          calcium: 0,
          iron: 0,
          potassium: 0
        },
        description: '',
      },   
    ];
  
    export const nutritionalData = {
      veganEntreeData: veganEntreeData,
      grilledMeatsData: grilledMeatsData,
      smokedMeatsData: smokedMeatsData,
      sidesData: sidesData,
      drinksDessertsData: drinksDessertsData,
    }