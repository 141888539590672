import React, { useState } from "react"
import {
  Box,
  Button,
  Typography,
  Menu,
  MenuItem,
  Drawer,
  SwipeableDrawer,
  AppBar,
  Fab,
  Grid,
  IconButton,
  Toolbar,
  keyframes,
} from "@mui/material"
import SmallLogo from "../assets/CharcoalCrazeLogoWhite-NoText.png"
import HamburgerIcon from "../assets/hamicon.png"
import { Close, MenuTwoTone } from "@mui/icons-material"
import AnchorLink from "react-anchor-link-smooth-scroll"
import "./navbar.css"
import { Link, useNavigate } from "react-router-dom"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

const pages = [
  { title: "Home", linkTo: "/", enabled: true },
  { title: "Menu", linkTo: "/Menu", enabled: true },
  { title: "Todays Menu", linkTo: "/TodaysMenu", enabled: true},
  { title: "Vending Schedule", linkTo: "/VendingSchedule", enabled: true},
]

const itemFocusedAnimation = keyframes`

0% { 
    opacity: 1;
    background-position: 70% 70%;
    outline: 1px solid rgba(255,255,255,.3);
}

50% { 
    opacity: 1;
    background-position: 10% 30%;
    outline: 1px solid rgba(255,255,255,1);
}



100% { 
    opacity: 1;
    background-position: 0% 30%;
    outline: 1px solid rgba(255,255,255,.3);
}

`

export function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const navigate = useNavigate()

  const [trigger2, setTrigger2] = React.useState(true)

  useScrollPosition(({ prevPos, currPos }) => {
    const isShow = currPos.y === 0
    if (!isShow) {
      setTrigger2(true)
    } else {
      setTrigger2(false)
    }
  }, [])

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          w: "100vw",
          transition: "all 0.3s ease-in-out",
          backgroundColor: "transparent",
          height: trigger2 ? "80px" : "110px",
        }}
        id="back-to-top-anchor"
      >
        <Grid container flexGrow={1}>
          <Toolbar disableGutters sx={{ width: "100%" }}>
            <Grid
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "flex", lg: "flex" },
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Grid item justifyContent="center" alignItems="center">
                <IconButton
                  size="large"
                  aria-label="Open navigation menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                  sx={{
                    transition: "all 0.3s ease-in-out",
                    color: "#c0c0c0",
                    ":hover": { color: "white" },
                  }}
                >
                  <MenuTwoTone />
                </IconButton>
              </Grid>

              <Grid
                item
                justifyContent="center"
                alignItems="center"
                sx={{
                  position: "absolute",
                  left: "calc(50% - 44px)",
                  transition: "all 0.4s ease-in-out",
                  transform: trigger2
                    ? `scale(.8) translateY(0)`
                    : `scale(0.8) translateY(25%)`,
                  "@media screen and (min-width: 300px)": {
                    transform: trigger2
                      ? `scale(.9) translateY(0)`
                      : `scale(1) translateY(25%)`,
                  },
                  "@media screen and (min-width: 350px)": {
                    transform: trigger2
                      ? `scale(.9) translateY(0)`
                      : `scale(1.5) translateY(25%)`,
                  },
                  "@media screen and (min-width: 600px)": {
                    transform: trigger2
                      ? `scale(.9) translateY(0)`
                      : `scale(1.8) translateY(25%)`,
                  },
                  "@media screen and (min-width: 850px)": {
                    transform: trigger2
                      ? `scale(.9) translateY(0)`
                      : `scale(2) translateY(28%)`,
                  },
                }}
              >
                <img
                  alt="Charcoal Craze logo"
                  src={SmallLogo}
                  style={{
                    height: "80px",
                    width: "auto",
                    maxWidth: "300px",
                    alignItems: "center",
                    verticalAlign: "middle",
                    cursor:
                      window.location.pathname === pages[0].linkTo
                        ? "default"
                        : "pointer",
                  }}
                  onClick={() => {
                    navigate(pages[0].linkTo)
                  }}
                />
              </Grid>
              <Drawer
                id="menu-appbar"
                anchor="left"
                keepMounted
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                open={Boolean(anchorElNav)}
                onClose={() => handleCloseNavMenu()}
                sx={{
                  width: { xs: "100vw", md: "300px" },
                  textAlign: "center",
                  "& .MuiDrawer-paper": {
                    bgcolor: "rgb(22,22,22)",
                    width: { xs: "100vw", md: "300px" },
                    boxSizing: "border-box",
                    paddingTop: "60px",
                    backdropFilter: "blur(7px)",
                  },
                }}
              >
                <Fab
                  size="small"
                  aria-label="close menu"
                  sx={{
                    position: "absolute",
                    top: "14px",
                    right: "14px",
                    "&:hover": {
                      bgcolor: "rgba(140,140,140)",
                    },
                  }}
                  onClick={() => handleCloseNavMenu()}
                >
                  <Close />
                </Fab>
                <Grid container justifyContent="center" flexDirection="column">
                  {pages.map((page) => (
                    <MenuItem
                      key={page.title}
                      onClick={() => {
                        navigate(page.linkTo)
                        handleCloseNavMenu()
                      }}
                      href={page.linkTo}
                      disabled={(page.linkTo === window.location.pathname) || (page.enabled === false)}
                      sx={{
                        color: "rgb(247,247,247)",
                        cursor: "pointer",
                        fontSize: "22px",
                        borderBottom: "1px solid rgb(80,80,80)",
                        marginLeft: "20px",
                        marginRight: "20px",
                        whiteSpace: "wrap",
                        textAlign: "left",

                        "&:hover": {
                          color: "rgb(140,140,140)",
                          borderBottom: "1px solid rgb(80,80,80)",
                        },
                        "&:active:hover": {
                          color: "rgb(247,247,247)",
                        },
                        "&:focus": {
                          animation: `${itemFocusedAnimation} 2s ease-in-out infinite`,
                        },
                        "&.Mui-disabled": {
                          color: "rgb(255,255,255)",
                          cursor: "default",
                          fontWeight: "bold",
                          textDecoration: "underline",
                          borderBottom: "1px solid rgb(160,160,160)",
                          animation: "none!important",
                        },
                      }}
                      tabIndex={0}
                    >
                      {page.title}
                    </MenuItem>
                  ))}
                </Grid>
              </Drawer>
            </Grid>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                color: "white",
                backgroundColor: "rgba(0,0,0,0)",
                display: "block",
                backdropFilter: "blur(14px)",
                zIndex: "-1",
                position: "absolute",
              }}
            />
          </Toolbar>
        </Grid>
      </AppBar>
    </>
  )
}
